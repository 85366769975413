
























































































































































































































































































































































































































































































































































































import {
  Component, Vue, Prop, Watch, Model,
} from 'vue-property-decorator';
import { getComponent, getView } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class FormFieldDetails extends Vue {
   @Prop({ type: Object, required: false }) fieldParent?: any;

   @Prop({ type: Array, required: false }) allFields?: any;

   @Prop({ type: Boolean, default: false, required: false }) isPreviewMode?: any;

   windowWidth = window.innerWidth;

   dropdownLocation: any = []

   showDetails = true;

   openTab = 1

   activeTableID: any = null;

   visibilitySelectedField = [];

   field: any = {}

   localField: any = {}

   created() {
     this.showDetails = this.fieldParent.showDetails;
     this.field = { ...this.fieldParent };
     //  this.field = {... this.parentField};
     this.localField = { ...this.fieldParent };
   }

   get sourceOptions() {
     console.log(metaDataModule.datalists);
     const { datalists } = metaDataModule;

     //  datalists = datalists.push({ title: 'Custom', id: 'CUSTOM' })

     return datalists.map((list) => ({
       text: list.title,
       value: list.id,
     }));
     //  return result;
   }

   get getStylesForDoneButton() {
     if (!this.field.title.length) {
       return 'background: #b7b7b7; cursor: no-drop';
     }
     return 'background: #5be5c1; width: 175px;';
   }

   async selectSource() {
     await this.dropdownOptions(this.field);
   }

   async selectSourceType(item) {
     if (this.field.sourceType !== 'Custom') {
       this.field.isCustomSource = false;
     } else {
       this.field.isCustomSource = true;
     }
   }

   activeTasqTab = 'General'

   selectedInputType = 'Textbox'

   addDependency() {
     if (this.field.visibilityRules && this.field.visibilityRules.length) {
       this.field.visibilityRules.push({
         condition: 'AND', fieldName: '', fieldId: '', matchValue: '',
       });
     } else {
       this.field.visibilityRules.push({
         condition: null, fieldName: '', fieldId: '', matchValue: '',
       });
     }
   }

   resetField() {
     this.fieldParent.showDetails = true;
     this.$emit('fresh-form');
   }

   doneFieldEditing() {
     this.field.showDetails = false;

     this.$emit('update-form-field', { id: this.localField.id, data: this.field });
   }

   get allFieldsOptions() {
     return this.allFields.filter((field) => field.category === 'Dropdown').filter((field) => field.title !== this.field.title).map((f) => f.title);
   }

   get availableTabs() {
     if (['Textbox', 'Dropdown', 'Date/Time'].includes(this.field.category)) {
       return [{ name: 'General', id: 1 }, { name: 'Settings', id: 2 }, { name: 'Visibility', id: 3 }];
     }
     return [{ name: 'General', id: 1 }, { name: 'Visibility', id: 3 }];
   }

   optionForField(title) {
     const field = this.allFields.find((f) => f.title === title);

     if (field && field.options) {
       return field.options;
     }
     return [];
   }

   toggleTabs(tab) {
     this.openTab = tab.id;
     this.activeTasqTab = tab.name;
   }

   removeNewFieldOption(option) {
     this.field.options = this.field.options.filter((e) => e !== option);
   }

   removeVisbitlityRule(key) {
     this.field.visibilityRules.splice(key, 1);
     if (this.field.visibilityRules && this.field.visibilityRules.length) {
       this.field.visibilityRules[0].condition = null;
     }
   }

   showFieldDetails() {
     const openField = this.allFields.find((f) => f.showDetails);
     if (openField) {
       this.$emit('already-open-field-msg', openField.title);
       return;
     }
     this.fieldParent.showDetails = true;
     this.showDetails = true;
   }

   async dropdownOptions(id) {
     if (this.field.isCustomSource) {
       this.field.options = [];
     }
     if (this.field.source && this.field.source.value !== 'CUSTOM') {
       const datalistPayload = await metaDataModule.getDatalist(this.field.source.value);
       console.log(datalistPayload);
       if (datalistPayload && datalistPayload.length) {
         console.log(datalistPayload[0].ListItems);
         this.field.options = datalistPayload[0].ListItems.map((item) => item.Name);
       }
     }
   }
}

